import React from "react"

import Layout from "src/components/common/Layout"
import SeriesSection from "src/components/page/series/SeriesSection"

const SeriesPage = () => {
  return (
    <Layout title="Series">
      <SeriesSection />
    </Layout>
  )
}

export default SeriesPage
