import React from "react"
import YoutubeButton from "src/components/common/YoutubeButton"
import styled from "styled-components"

const SeriesCard = ({ name, title, image, description, link }) => {
  return (
    <StyledSeriesCard>
      <div className="series-card__image">
        <a href={link} target="_blank" rel="noreferrer">
          <img src={image} alt={name} />
        </a>
      </div>
      <div className="series-card__content">
        <div className="series-card__content__top">
          <h3 className="series-card__title">{title}</h3>
          <div className="series-card__youtube-button">
            <YoutubeButton
              link={link}
              customCSS={`
              font-size: 0.8rem;
              color: #fff;
              background-color: #000;
              border: 1px solid rgba(79, 79, 79, 1);
              padding: 7px 22px;
            `}
              target="_blank"
            />
          </div>
        </div>
        <p className="series-card__description">{description}</p>
      </div>
    </StyledSeriesCard>
  )
}

export default SeriesCard

const StyledSeriesCard = styled.div`
  max-width: 450px;
  .series-card__image {
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
  .series-card__content {
    display: flex;
    flex-direction: column;
    .series-card__content__top {
      display: flex;
      justify-content: space-between;
      margin-top: 19px;
      margin-bottom: 19px;
      h3 {
        font-size: 1.1rem;
      }
      .series-card__youtube-button {
        flex-shrink: 0;
      }
    }
    .series-card__description {
      font-size: 0.9rem;
      line-height: 1.7;
    }
  }
`
