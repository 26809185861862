import React from "react"
import SeriesCard from "src/components/common/SeriesCard"
import styled from "styled-components"

const list = [
  {
    name: "No Chill with Gilbert Arenas",
    title: (
      <>
        No Chill <br />
        with Gilbert Arenas
      </>
    ),
    image:
      "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/shows/no-chill-horizontal.jpg?ch=width&auto=format,compress",
    description:
      "Gilbert Arenas shares his basketball insights with up-and-coming prospects, current and former NBA stars, and coaches.",
    url: "https://www.youtube.com/c/fubosports",
  },
  {
    name: "Young Person Basketball Podcast with R.J. Hampton",
    title: <>Young Person Basketball Podcast with R.J. Hampton</>,
    image:
      "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/shows/young-person-horizontal.jpg?ch=width&auto=format,compress",
    description:
      "R.J. Hampton dives into what life behind the scenes is really like for current and former players NBA players, including team prank wars, wild DMs, dating as a celebrity, and more.",
    url: "https://www.youtube.com/c/fubosports",
  },
  {
    name: "Getcha Popcorn Ready With T.O. & Hatch",
    title: (
      <>
        Getcha Popcorn Ready
        <br />
        with T.O. and Hatch
      </>
    ),
    image:
      "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/shows/getcha-popcorn-ready-horizontal.jpg?ch=width&auto=format,compress",
    description:
      "Former wide receivers Terrell Owens and Matthew Hatchette go deep on conversations with celebrities and athletes.",
    url: "https://www.youtube.com/c/fubosports",
  },
  {
    name: "Airing It Out With Housh & Scandrick",
    title: (
      <>
        Airing It Out <br />
        With Housh & Scandrick
      </>
    ),
    image:
      "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/shows/airing-it-out-horizontal.jpg?ch=width&auto=format,compress",
    description:
      "Former players T.J. Houshmandzadeh and Orlando Scandrick show what the life of an NFL player is really like, have conversations with some of the top talent in the game, and give unvarnished reactions to stories around the league.",
    url: "https://www.youtube.com/c/fubosports",
  },
]

const SeriesSection = () => {
  return (
    <StyledSeriesSection>
      <h1>Series</h1>
      <div className="shows-section">
        {list.map((item, index) => (
          <SeriesCard
            name={item.name}
            title={item.title}
            image={item.image}
            description={item.description}
            link={item.url}
            key={index}
          />
        ))}
      </div>
    </StyledSeriesSection>
  )
}

export default SeriesSection

const StyledSeriesSection = styled.div`
  color: #fff;
  padding: 60px 40px;
  h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
  }
  .shows-section {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, auto);
    grid-gap: 118px 40px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, auto);
    }
  }
`
